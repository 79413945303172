import React from 'react';
import Helmet from 'react-helmet';
import { withI18n } from '@lingui/react';
import { prefix } from '../i18n-config';
import { Link } from 'react-scroll';


function PrivacyPolicy(props) {
  const { pageContext, data, location } = props;
  const lang = pageContext.lang;

  return (
    <div className="page page--privacy-policy page--light">
      <Helmet titleTemplate="%s - Interstellar Interactive">
        <meta name="og:type" content="website" />
        <meta name="og:site_name" content="Interstellar Interactive" />
        <title>Privacy Policy</title>
        <link
          rel="canonical"
          href={`https://www.interstellarinteractive.com${location.pathname}`}
        />
        <html lang={lang} />
        {/* <meta name="description" content={content.seo} /> */}
      </Helmet>
      <div className="page__content">
        <div className="container" name="back-to-top">
          <div className="page-title-section">
            <h1>Interstellar Interactive® - Privacy Policy</h1>
            <h4 className="date-updated">Last updated: May 6, 2022</h4>
          </div>
          <div className="line"></div>
          <div className="navigation-list">
            <Link className="" to="consent" smooth offset={-45}>Consent</Link>
            <Link className="" to="retention" smooth offset={-45}>Retention of Personal Information</Link>
            <Link className="" to="protection-and-accurancy" smooth offset={-45}>Protection and accuracy of the Personal Information</Link>
            <Link className="" to="access-to-information" smooth offset={-45}>Access to Pe smooth offset={-45}rsonal Information and modification of Personal Information</Link>
            <Link className="" to="information-of-payments" smooth offset={-45}>Information on Payments</Link>
            <Link className="" to="collection-of-additional-personal-information" smooth offset={-45}>Collection of additional Personal Information through the Services</Link>
            <Link className="" to="cookies" smooth offset={-45}>Cookies</Link>
            <Link className="" to="crossborder-transfer" smooth offset={-45}>Cross-Border Transfer</Link>
            <Link className="" to="hyperlinks" smooth offset={-45}>Hyperlinks to th smooth offset={-45}ird party websites and third-party social media</Link>
            <Link className="" to="required-age" smooth offset={-45}>Required Age</Link>
            <Link className="" to="questions-comments-suggestions-or-complaints" smooth offset={-45}>Questions, comments, suggestions or complaints</Link>
            <Link className="" to="european-union-notice" smooth offset={-45}>European Union (EU) Notice</Link>
            <Link className="" to="transfers-of-personal-information" smooth offset={-45}>Transfers of Personal Information</Link>
            <Link className="" to="opt-in" smooth offset={-45}>Opt-in</Link>
            <Link className="" to="your-legal-rights" smooth offset={-45}>Your Legal Rights</Link>
            <Link className="" to="how-to-contact-the-privacy-officer" smooth offset={-45}>How to contact the Privacy Officer</Link>
          </div>
          <div className="line"></div>
          <div name="consent" className="section-wrapper">
            <h2>1. Consent</h2>
            <p>The Company collects Users’ Personal Information for the purposes outlined below.</p>

            <p>In addition to the purposes set out this Privacy Policy, the Company will identify the purposes for which Personal Information is collected at or before the time it is collected. If the Company seeks to use Personal Information that has been collected for a purpose not previously identified, it will seek your consent prior to use, unless it is otherwise required by law.</p>

            <p>Subject to legal or contractual requirements, Users may at any time withdraw their consent to the collection, use or disclosure of their Personal Information by writing to the Privacy Officer of the Company at the coordinates below, giving reasonable notice. However, the withdrawal of such consent could limit the capacity of the Company to offer certain specific Services to the User, prevent the Company from providing same or even force the Company to cease from providing same. Moreover, even in case of withdrawal of consent, the Company may nevertheless by law have the right or obligation to retain, use or disclose Personal Information about a User.</p>

            <p>Purposes for collecting and using Personal Information</p>

            <p>The Company may collect and use Personal Information for the following purposes:</p>

            <ol type="a">
              <li>
                <p>Provide customized Services or information requested by Users;</p>
              </li>
              <li>
                <p>Communicate with Users as part of the activities of the Company, for internal quality assurance purposes or to manage the quality of the information provided;</p>
              </li>
              <li>
                <p>Detect and prevent fraud;</p>
              </li>
              <li>
                <p>For statistical and accounting purposes;</p>
              </li>
              <li>
                <p>Inform Users of news, updates, information and special offers concerning the Services of the Company, in accordance with applicable anti-spam legislation;</p>
              </li>
              <li>
                <p>If the Company has reasonable grounds to believe that it might be useful as part of the investigation of a violation of provincial, federal or foreign law or to ensure the protection or defence of a legal interest;</p>
              </li>
              <li>
                <p>Administer the User’s account on any of our Services, including rewards programs, sweepstakes, and contests for which the User enters, including notifying of the results.</p>
              </li>
              <li>
                <p>As required or permitted by law.</p>
              </li>
            </ol>
          </div>
          <div name="retention" className="section-wrapper">
            <h2>2. Retention of Personal Information</h2>
            <p>The Company retains the Personal Information it collects only as long as it is necessary to conduct its activities and accomplish specific goals while it is reasonable to do so for customer service, legal or commercial purposes. The length of retention period depends on the Service and the nature of this information. When the retention period expires, the information is destroyed, deleted or made anonymous. </p>

            <p><u>Disclosure of Personal Information</u></p>

            <p>The Company agrees not to publish, sell, rent or share the Personal Information of the Users with anyone except to the extent allowed in this Policy.</p>

            <p>The Company will disclose Personal Information about Users to third parties only in the following situations:</p>

            <ol type="a">
              <li>
                <p>The User has expressly authorized the Company to disclose the information provided for a specific purpose;</p>
              </li>
              <li>
                <p>The third parties involved are acting on behalf of the Company as agents or suppliers, and the information will be disclosed to them only in order to assist the Company in providing the Services, it being understood that these third parties shall respect strict contractual conditions that oblige them to maintain the confidentiality of all information and use it only for purposes related to the supply of the Services by the Company;</p>
              </li>
              <li>
                <p>If a person is acting as a representative of the User or if the Company reasonably believes that the person is acting pursuant to an appropriate authorization (for example as tutor, holder of parental authority, etc.), or is a person who owns a Service jointly with the User;</p>
              </li>
              <li>
                <p>In accordance with the conditions applicable to a Service of the Company, to facilitate the sale of the Service, in particular to satisfy legal requirements;</p>
              </li>
              <li>
                <p>If the Company is required to provide Personal Information in response to a legitimate court order or subpoena to submit documents or investigation by authorities or is otherwise required by law;</p>
              </li>
              <li>
                <p>The Company reserves the right to report to law enforcement authorities any activity that the Company thinks in good faith to be illegal;</p>
              </li>
              <li>
                <p>The Company may disclose certain Personal Information when it considers such disclosure reasonably necessary to protect the rights, property and safety of third parties as well as those of the Company or to ensure Users’ compliance with its obligations in regards to the Company;</p>
              </li>
              <li>
                <p>Should the Company sell its business or assets, in whole or in part, or is involved in a merger, as required by such a transaction.</p>
              </li>
            </ol>
          </div>
          <div name="protection-and-accurancy" className="section-wrapper">
            <h2>3. Protection and accuracy of the Personal Information</h2>
            <p>The Company takes seriously the management of the Personal Information of Users.</p>

              <p>Only authorized Company personnel have access to the Personal Information of Users. These employees have been informed by the Company of their obligation to preserve the confidentiality of this Personal Information. The Company employees who have access to Personal Information may use it only in accordance with the principles set out in this Policy and applicable legislation and regulation.</p>

              <p>The security and protection of Personal Information is important to the Company. The Company applies reasonable technical, contractual, administrative and tangible measures to protect the Personal Information of Users against its unauthorized use or disclosure as well as its loss or theft, including encryption of secured transactions when required. Although it is impossible to guarantee 100% protection, the Company has adopted rigorous methods that comply with strict security standards to ensure protection of Personal Information.</p>

              <p>We also limit access to your Personal Information to those employees, contractors and agents who have a business need to know.</p>

              <p>Depending on the nature of Personal Information, it may be stored in the offices of the Company or in various computer systems of the Company or its service suppliers or in storage installations of the Company or its service suppliers.</p>

              <p>The Company will ensure that the Personal Information about Users is as accurate, complete and up-to-date as possible for the purposes for which it is used. It is however the responsibility of the User to update Personal Information, such as addresses, with the Company.</p>
        </div>
        <div name="access-to-information" className="section-wrapper">
            <h2>4. Access to Personal Information and modification of Personal Information</h2>
            <p>Users may access their own Personal Information in order to consult, update or correct such, by contacting the Company’s Privacy Officer in writing by email or regular mail at the coordinates provided below.</p>

            <p>Any such request must contain sufficient details for the Company to be able to process it. The Company agrees to respond to any such request within a reasonable period of time, and to the extent possible, within a maximum of thirty (30) days. The Company must however first verify the identity of the person making such a request.</p>

            <p>The Company may refuse in writing access to or modification of Personal Information of a User for serious reasons, such as the exorbitant cost of supplying the information, the fact that the Personal Information contains information about other persons, the existence of legal, security or proprietary commercial reasons and the fact that the information is protected by professional secrecy, attorney-client privilege, or involved in a legal proceeding.</p>

            <p>The Company may charge fees for the transcription, copying or transmission of Personal Information of a User and will so advise the User in advance.</p>
          </div>
          <div name="information-of-payments" className="section-wrapper">
            <h2>5. Information on Payments</h2>

            <p>The Company does not collect any information relating to payment cards. Payments made via the Services are processed directly by the Company’s service provider, which has sole access to payment card information.</p>

            <p>Your on-line transactions are secured in accordance with the transmission standards used by the Company’s service provider, so that your transactions are routed and processed securely.</p>
          </div>

          <div name="collection-of-additional-personal-information" className="section-wrapper">
            <h2>6. Collection of additional Personal Information through the Services</h2>

            <p>As part of the use of the Services online, the Company may collect additional Personal Information (“<b>Online Information</b>”) about the use of the Services, and this Policy applies to this Online Information with the appropriate adjustments. General information, such as demographic statistics about Users, the number of Users and the average time of use of the Services, is likewise considered Online Information.</p>

            <p>The Company collects or obtains Online Information as part of the operation of the Services. For example, when a User decides to use the Services, the Company may automatically collect information obtained by way of analytical tools or digital markers. These analytical tools collect only a limited dataset, in particular the time and date when a Service was used and the IP address. A Service may also contain analytical tools placed by advertising partners or service providers in order to allow the Company to evaluate the effectiveness of the Services, including Google Analytics, Google Marketing Platform and Google Ad Manager.</p>

            <p>Google Analytics is a web analytics service offered by Google that tracks and reports website traffic. Google uses Online Information collected to track the use of the Services and to personalize ads. You can opt-out of having your Online Information being used by Google Analytics for example by installing the Google Analytics opt-out browser add-on available at: <a href="https://tools.google.com/dlpage/gaoptout">https://tools.google.com/dlpage/gaoptout</a>. More information on the privacy practices of Google is available at: <a href="https://policies.google.com/technologies/partner-sites">https://policies.google.com/technologies/partner-sites</a></p>

            <p>More information on how Google Marketing Platform advertising products and Google Ad Manager use cookies is available at: <a href="https://support.google.com/admanager/answer/2839090?hl=en">https://support.google.com/admanager/answer/2839090?hl=en</a></p>

            <p>In addition to what is already provided in this Policy in regards to Personal Information, the Company uses Online Information to track the use of the Services and improve their use, customize the experience of the Users, in particular by proposing customized advertisements and offers, make statistical analyses of all the characteristics and behaviours of Users, measure the demographic variables and interests of Users with respect to specific Services, describe its Services to third parties and potential commercial partners and determine how and where Company resources can be allocated more effectively. No Personal Information is disclosed as part of such activities.</p>

            <p>This Online Information is necessary for the Company to be able to collect the data required to record the number of Users, the functionalities or aspects of the Services most used, the technology used by the Users of the Services, the referral websites and the location of the Users.</p>
          </div>

          <div name="cookies" className="section-wrapper">
            <h2>7. Cookies</h2>
            <p>The Company may also use cookies. Cookies are fragments of information sent to Users computer when a website is viewed. These are traces of the Users’ visit that are recorded either temporarily or permanently on their hard drive and may include the date and time of the last visit, among other things. Cookies contribute to improving Users’ online experience by recording browsing preferences on a particular website.</p>

            <p>Cookies left on Users’ hard drive are harmless and may be read or deleted at any time. Users therefore have complete control over them. The information allows the Company to determine the total number of visitors to each web page and the time spent on it. Every time Users go to a website, a cookie is created and left on their computer, therefore allowing the Company to “recognize” them.</p>

            <p>Many major websites use cookies, and most browsers are configured to allow cookies. The Users may choose to change their browser settings so that a message is automatically sent to ask whether to accept or refuse cookies. While Users are not required to accept cookies, they should be aware that certain website functionalities may not work properly if cookies are refused.</p>

            <p>The Services may use retargeting services (for example Google Analytics) to identify Users who have used the Services and reach them with online advertising once they have stopped using the Services, in order to transmit advertisements associated with prior use of the Services, without however disclosing any Personal Information in doing so.</p>
          </div>

          <div name="crossborder-transfer" className="section-wrapper">
            <h2>8. Cross-Border Transfer</h2>
            <p>The Company processes and stores Personal Information using our server(s) based in Canada.</p>

            <p>If you are located outside of Canada, your Personal Information and other information that we collect may be transferred to Canada, for the purposes described above. By accepting this Policy, using our Services or providing us with any Personal Information, you agree to the transfer of information to Canada.</p>
          </div>

          <div name="hyperlinks" className="section-wrapper">
            <h2>9. Hyperlinks to third party websites and third-party social media</h2>
            <p>The Services may contain hyperlinks to websites of third parties (“<b>Third Party Websites</b>”) that can cause leaving the website currently browsed by the User. The Company provides these hyperlinks for convenience. The Third Party Websites are not under the control of the Company, which therefore has no control over the privacy and confidentiality practices of such Third Party Websites. Accordingly, any Personal Information that is transmitted by use of Third Party Websites is subject to the privacy policy of the relevant Third Party Websites. It is the responsibility of the Users to consult these policies in order to ensure that their Personal Information is being protected.</p>

            <p>The Company uses social media (for example, Facebook and Twitter) in addition to the operation of the Services. Social media accounts are public and are not hosted by the Company. Users who decide to interact with the Company through social media must therefore read the terms of service and privacy policies of these third-party suppliers of services and the applications used to have access to them.</p>

            <p>The Personal Information that is provided to the Company by way of social media accounts is collected in order to record exchanges (for example, questions and answers, comments, “likes”, shared tweets) between a User and the Company. It may be used to answer requests, make statistical evaluations and to prepare reports and for all other purposes indicated in this Policy.</p>
          </div>

          <div name="required-age" className="section-wrapper">
            <h2>10. Required Age</h2>

            <p>Access to all Services is intended only for persons of legal age (the age of majority) based on the jurisdiction where such Service is being accessed or viewed. You will be required to verify your date of birth prior to entering or interacting with the Services. Age verification prompts may also be located within the Services.</p>

            <p>Notwithstanding the foregoing, if you are located in Canada, the United States or Mexico, you are permitted to access and use the Services if you are 17 of age or older.</p>

            <p>The Company does not knowingly collect information from those under the legal age and requests that such individuals do not provide any information to the Company or access the Services. If you are under the legal age in your jurisdiction and have entered the Services by providing a false date of birth, you must immediately stop accessing the Services. If you believe someone under the legal age has provided the Company with information by obtaining access in violation of the applicable Services’ access restrictions, please contact the Company immediately and this information will be removed.</p>
          </div>

          <div name="questions-comments-suggestions-or-complaints" className="section-wrapper">
            <h2>11. Questions, comments, suggestions or complaints</h2>

            <p>For any questions, comments or suggestions regarding this Policy or the Personal Information protection practices of the Company not discussed in this Policy, you can write to the Company’s Privacy Officer by email or regular mail at the coordinates below. You can submit complaints to the Company’s Privacy Officer if you think that the Company is not meeting its obligations with respect to the protection of Personal Information. The Company will investigate such complaints and if they are justified, will take appropriate measures to correct the situation. If you are not satisfied with this internal review of your complaint, you can contact the <i>Commission d’accès à l’information du Québec</i> or the Office of the Privacy Commissioner of Canada.</p>
          </div>

          <div name="european-union-notice" className="section-wrapper">
            <h2>12. European Union (EU) Notice</h2>

            <p>If you are located in a country in the European Union (the “<b>EU</b>”) or utilize our Services which are offered to EU residents, and if we collect, track, use or process in some other way your Personal Information collected from you or through your use of our Services, or we transfer that Personal Information, we will do so in accordance with this Policy, the applicable Terms and Conditions, and in compliance with applicable requirements of the General Data Protection Regulation (EU 2016/679) (GDPR).</p>
          </div>

          <div name="transfers-of-personal-information" className="section-wrapper">
            <h2>13. Transfers of Personal Information</h2>

            <p>The Company is a data controller and responsible for your Personal Information, which Company processes and stores in Canada. The European Commission has decided that Canada ensures an adequate level of protection of individuals’ personal information. The Company may use the following safeguards when transferring your Personal Information to a country, other than Canada, that is not within the EU:</p>

            <ol type="a">
              <li>
                <p>Only transfer your Personal Information to countries that have been deemed by the European Commission to provide an adequate level of protection for Personal Information;</p>
              </li>
              <li>
                <p>Where we use certain service providers, we may use specific contracts which give Personal Information the same protection it has in the EU. </p>
              </li>
            </ol>
          </div>

          <div name="opt-in" className="section-wrapper">
            <h2>14. Opt-in</h2>
            <p>If you are an EU resident, we may only collect your data using cookies and similar devices, and then track and use your Personal Information where you have first consented to such. We will not automatically collect Personal Information from you as described above unless you have consented to us doing so. If you consent to our use of cookies and similar devices, you may at a later date disable them.</p>
          </div>

          <div name="your-legal-rights" className="section-wrapper">
            <h2>15. Your Legal Rights</h2>

            <p>Under certain circumstances, you may have rights under the data protection laws in relation to your Personal Information, including the right to:</p>

            <ul>
              <li>
                <p>Request access to your Personal Information.</p>
              </li>
              <li>
                <p>Request correction of your Personal Information.</p>
              </li>
            </ul>

            <ul>
              <li>
                <p>Request erasure of your Personal Information.</p>
              </li>
              <li>
                <p>Object to processing of your Personal Information.</p>
              </li>
              <li>
                <p>Request restriction of processing your Personal Information.</p>
              </li>
              <li>
                <p>Request transfer of your Personal Information.</p>
              </li>
              <li>
                <p>Right to withdraw (revoke) consent.<br /><br /></p>
              </li>
            </ul>

            <p>If you wish to exercise any of these rights, please contact our Privacy Officer at the coordinates below.</p>
          </div>

          <div name="how-to-contact-the-privacy-officer" className="section-wrapper">
            <h2>16. How to contact the Privacy Officer</h2>

            <p>Company is responsible for Personal Information under its control and has designated a Privacy Officer who is accountable for this Policy and applicable Canadian and other privacy and data protection laws. Should you have any questions about your Personal Information or Company’s privacy practices, please contact our Privacy Officer at the information below.</p>

            <p>Users may contact us with requests that we delete their Personal Information from our systems, or to request access or correction to their Personal Information. We will attempt to accommodate such requests to the extent possible. If all such information is deleted from our systems, your account may become deactivated. In any event, we may retain an archived copy of your records as required by law or for legitimate business purposes.</p>

            <p>The Privacy Officer may be contacted at:</p>

            <p>privacy:[at]:interstellarinteractive.com</p>

          </div>

          <Link className="scroll-to-top" to="back-to-top" smooth offset={-1000}>Back to top</Link>
        </div>
      </div>
    </div>
  );
}

export default withI18n()(PrivacyPolicy);

